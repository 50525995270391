import React, { useState } from 'react';
import './style.css'; // Import the CSS file
import ninoGif from './nino.gif'; // Import the GIF file

function GifComponent() {
    const [isLoaded, setIsLoaded] = useState(false);

    const handleLoad = () => {
        setIsLoaded(true);
    };

    return (
        <div className="container">
            {!isLoaded && <p>Loading...</p>}
            <img
                src={ninoGif}
                alt="GIF"
                className={`gif-container ${isLoaded ? 'loaded' : ''}`}
                onLoad={handleLoad}
                loop
            />
        </div>
    );
}

export default function App() {
    return (
        <div className="App">
            <GifComponent />
        </div>
    );
}
